import { z } from "zod";

export const EmailSchema = z.string().email().min(1).max(255);

export const PasswordSchema = z
  .string()
  .transform((str) => str.trim())
  .refine(
    (password) =>
    // ^                # Asserts the start of the string
    // (?=.*[a-z])      # Positive lookahead to ensure there is at least one lowercase letter
    // (?=.*[A-Z])      # Positive lookahead to ensure there is at least one uppercase letter
    // (?=.*\d)         # Positive lookahead to ensure there is at least one digit
    // (?=.*[^a-zA-Z\d\s\t])  # Positive lookahead to ensure there is at least one special character that is not alphanumeric, space, or tab
    // [^\s\t]{8,100}   # Matches any character that is not whitespace or tab, between 8 and 100 times
    // $                # Asserts the end of the string
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s\t])[^\s\t]{8,100}$/
.test(
        password
      ),
    {
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be between 8 and 100 characters.",
    }
  );

//Password Schema :  This schema will enforce that the password is a string, has a minimum length of 8, a maximum length of 100, and contains at least one uppercase letter, one lowercase letter, one number, and one special character.

//Schemas for Auth Routes
export const LoginSchema = z
  .object({
    email: EmailSchema,
    password: PasswordSchema,
  })
  .strict();

export const ForgetPasswordSchema = z
  .object({
    email: EmailSchema,
  })
  .strict();

export const ResetPasswordSchema = z
  .object({
    password: PasswordSchema,
    password_confirmation: PasswordSchema,
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Password and password confirmation must match.",
  });
