"use client";

import { createContext, useState } from "react";
import { useRouter } from "next/navigation";
import { deleteCookie } from "cookies-next";

interface AuthContextState {
  isLoggedIn: boolean;
  // eslint-disable-next-line no-unused-vars
  onUserAuthenticated: () => void;
  handleUserLoggedOut: () => void;
}

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const router = useRouter();

  const handleUserAuthenticated = () => {
    router.replace("/");
    setIsLoggedIn(true);
  };

  const handleUserLoggedOut = () => {
    deleteCookie("auth");
    deleteCookie("appMode");
    setIsLoggedIn(false);
    router.refresh();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        onUserAuthenticated: handleUserAuthenticated,
        handleUserLoggedOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
