"use client";

import Link from "next/link";
import { useContext, useState, useTransition } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { LoginSchema } from "@/app/schemas/auth-schemas";
import { StatusCodes } from "http-status-codes";

const LoginForm: React.FC = () => {
  const { onUserAuthenticated } = useContext(AuthContext)!;
  const [isPending, startTransition] = useTransition();

  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();

  const onSubmitForm = async (formData: FormData) => {
    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
    };
    const result = LoginSchema.safeParse(data);
    if (!result.success) {
      setErrorMessages(result.error.issues.map((i) => i.message));
      return;
    }

    startTransition(async () => {
      var response = await fetch("/api/auth/login", {
        method: "POST",
        body: JSON.stringify(result.data),
      });

      if (response.status == StatusCodes.UNAUTHORIZED) {
        setErrorMessages([
          "Email and password are not recognized, please try again with different credentials.",
        ]);
      } else if (response.status != StatusCodes.OK) {
        // show global error message toast.
      } else {
        onUserAuthenticated(); // user authentication is succesfull.
      }
    });
  };
  return (
    <form action={onSubmitForm}>
      <h4 className="nv-heading-4 text-gray-700">Login to your Account</h4>
      <div className="form-control-group">
        <input
          className="form-control"
          type="email"
          name="email"
          placeholder="Email"
          onChange={() => {
            setErrorMessages([]);
          }}
        />
        <div className="form-field">
          <input
            className="form-control"
            type="password"
            placeholder="Password"
            name="password"
            onChange={() => {
              setErrorMessages([]);
            }}
          />
          <span className="nv-body-sm nv-fw-semibold">
            <Link href="/auth/forget-password">Forgot password?</Link>
          </span>
        </div>
        {errorMessages && (
          <div className="form-field">
            {errorMessages.map((m, idx) => (
              <span className="text-danger nv-body-sm" key={idx}>
                {m}
              </span>
            ))}
          </div>
        )}
        <button
          className="btn login-form__submit-btn text-white"
          disabled={isPending}
        >
          {isPending && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}{" "}
          <span> Login</span>
        </button>
      </div>
    </form>
  );
};

export { LoginForm };
